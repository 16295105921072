// material-ui
import { useTheme } from '@mui/material/styles';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Switch, PaletteMode, Box, Typography } from '@mui/material';

// project imports
import useConfig from 'hooks/useConfig';
import SubCard from 'ui-component/cards/SubCard';

const Layout = () => {
    const theme = useTheme();
    const { navType, rtlLayout, onChangeMenuType, onChangeRTL } = useConfig();

    return (
        // <Box
        //     sx={{
        //         padding: 2,
        //         borderRadius: '8px',
        //         backgroundColor: theme.palette.background.paper,
        //         boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        //         width: { xs: '100%', sm: '80%', md: '60%' }, // Adjust the width for different screen sizes
        //         margin: 'auto' // Center the box on the page
        //     }}
        // >
        //     <Typography variant="h6" sx={{ mb: 2, textAlign: 'center', color: theme.palette.text.primary }}>
        //         Giao diện
        //     </Typography>

        //     <FormControl component="fieldset" fullWidth>
        //         <RadioGroup
        //             row
        //             aria-label="layout"
        //             value={navType}
        //             onChange={(e) => onChangeMenuType(e.target.value as PaletteMode)}
        //             name="row-radio-buttons-group"
        //             sx={{
        //                 justifyContent: { xs: 'center', sm: 'flex-start' }, // Center items on mobile
        //                 gap: { xs: 2, sm: 4 } // Add spacing between radio buttons
        //             }}
        //         >
        //             <FormControlLabel
        //                 value="light"
        //                 control={<Radio />}
        //                 label="Sáng"
        //                 sx={{
        //                     '& .MuiSvgIcon-root': { fontSize: { xs: 24, sm: 28 } }, // Adjust icon size
        //                     '& .MuiFormControlLabel-label': { color: theme.palette.grey[900], fontSize: { xs: '1rem', sm: '1.2rem' } } // Adjust font size
        //                 }}
        //             />
        //             <FormControlLabel
        //                 value="dark"
        //                 control={<Radio />}
        //                 label="Tối"
        //                 sx={{
        //                     '& .MuiSvgIcon-root': { fontSize: { xs: 24, sm: 28 } },
        //                     '& .MuiFormControlLabel-label': { color: theme.palette.grey[900], fontSize: { xs: '1rem', sm: '1.2rem' } }
        //                 }}
        //             />
        //         </RadioGroup>
        //     </FormControl>
        // </Box>

        <FormControl component="fieldset" fullWidth>
            <FormControlLabel
                control={
                    <Switch
                        checked={theme.palette.mode === 'dark'}
                        onChange={(e) => onChangeMenuType((theme.palette.mode === 'light' ? 'dark' : 'light') as PaletteMode)}
                        inputProps={{ 'aria-label': 'dark mode toggle' }}
                    />
                }
                label="Chế độ tối"
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between', // Ensures text and switch are spaced apart
                    alignItems: 'center', // Vertically center the label and switch
                    width: '100%',
                    padding: '8px 16px',
                    borderRadius: '8px',
                    backgroundColor: theme.palette.background.default,
                    '& .MuiTypography-root': {
                        color: theme.palette.text.primary,
                        marginRight: 'auto' // Forces the text to take up the space on the left
                    }
                }}
                componentsProps={{
                    typography: {
                        sx: {
                            marginLeft: 0 // Ensure no additional margin on the left of the text
                        }
                    }
                }}
            />
        </FormControl>
    );
};

export default Layout;
