import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme, Theme } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import Dashboard from 'views/dashboard/Default';
import CustomizationIOS from '../CustomizationIOS';
import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';
import MacOSWindow from 'layout/CustomizationIOS/Apps/MacOSWindow';

// assets
import { IconChevronRight } from '@tabler/icons';
import useWindowManager from 'layout/CustomizationIOS/hooks/useWindowManager';
import { WindowManagerProvider } from 'layout/CustomizationIOS/Apps/MacOSWindow/Provider/macwindow.provider';

interface MainStyleProps {
    theme: Theme;
    open: boolean;
}

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }: MainStyleProps) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const {
        windows,
        activeWindow,
        clickedPrograms,
        minimizedWindows,
        openWindow,
        handleFocus,
        closeWindow,
        handleMinimize,
        handleRestore
    } = useWindowManager();

    const windowManagerValue = useMemo(() => ({ openWindow, clickedPrograms }), [openWindow, clickedPrograms]);

    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { container } = useConfig();

    React.useEffect(() => {
        dispatch(openDrawer(!matchDownMd));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    const header = useMemo(
        () => (
            <Toolbar>
                <Header />
            </Toolbar>
        ),
        []
    );

    return (
        <Box sx={{ display: 'flex', position: 'relative ' }}>
            <WindowManagerProvider value={windowManagerValue}>
                <CssBaseline />
                {/* header */}
                <AppBar
                    enableColorOnDark
                    position="fixed"
                    color="inherit"
                    elevation={0}
                    sx={{
                        bgcolor: theme.palette.background.default,
                        transition: drawerOpen ? theme.transitions.create('width') : 'none'
                    }}
                >
                    {header}
                </AppBar>

                {/* drawer */}
                <Sidebar />

                {/* main content */}
                <Main theme={theme} open={drawerOpen}>
                    {/* breadcrumb */}
                    {container && (
                        <Container maxWidth="lg">
                            <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                            <Outlet />
                        </Container>
                    )}
                    {!container && (
                        <>
                            <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                            <Outlet />
                        </>
                    )}
                </Main>

                <CustomizationIOS />

                {windows.map((window) => (
                    <MacOSWindow
                        key={window.id}
                        id={window.id}
                        title={window.title}
                        isActive={activeWindow === window.id}
                        onFocus={handleFocus}
                        onClose={closeWindow}
                        onMinimize={handleMinimize}
                        onRestore={handleRestore}
                        defaultMaximize={window.defaultMaximize}
                        maxWidth={window.maxWidth}
                        isMinimized={minimizedWindows.includes(window.id)}
                    >
                        {window.content}
                    </MacOSWindow>
                ))}
            </WindowManagerProvider>
        </Box>
    );
};

export default MainLayout;
