/* eslint-disable arrow-body-style */
import React, { createContext, useContext } from 'react';

// Define the context type
interface WindowManagerContextType {
    openWindow: (id: string, title: string, content: React.ReactNode, defaultMaximize?: boolean, maxWidth?: string) => void;
    clickedPrograms: string[];
}

// Create the context with default values
const WindowManagerContext = createContext<WindowManagerContextType | undefined>(undefined);

// Custom hook to use the WindowManagerContext
export const useWindowManagerContext = () => {
    const context = useContext(WindowManagerContext);
    if (!context) {
        throw new Error('useWindowManagerContext must be used within a WindowManagerProvider');
    }
    return context;
};

// Provider component
export const WindowManagerProvider: React.FC<{ children: React.ReactNode; value: WindowManagerContextType }> = ({ children, value }) => {
    return <WindowManagerContext.Provider value={value}>{children}</WindowManagerContext.Provider>;
};
