/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-plusplus */
/* eslint-disable yoda */
import * as React from 'react';

import { useMousePosition } from '../hooks/useMousePosition';
import { useWindowResize } from '../hooks/useWindowResize';

import { useDock } from '../Dock/DockContext';

import styles from './styles.module.scss';
import { Card } from '@mui/material';

interface DockCardProps {
    children: React.ReactNode;
}

const INITIAL_WIDTH = 48;

export const DockCard = ({ children }: DockCardProps) => {
    const cardRef = React.useRef<HTMLButtonElement>(null!);
    /**
     * This doesn't need to be real time, think of it as a static
     * value of where the card should go to at the end.
     */
    const [elCenterX, setElCenterX] = React.useState<number>(0);

    const size = 50;

    const dock = useDock();

    /**
     * This is just an abstraction around a `useSpring` hook, if you wanted you could do this
     * in the hook above, but these abstractions are useful to demonstrate!
     */

    useWindowResize(() => {
        setElCenterX(1);
    });

    const timesLooped = React.useRef(0);
    const timeoutRef = React.useRef<any>();
    const isAnimating = React.useRef(false);

    const handleClick = () => {
        if (!isAnimating.current) {
            isAnimating.current = true;

            timesLooped.current = 0;
        } else {
            /**
             * Allow premature exit of animation
             * on a second click if we're currently animating
             */
            clearTimeout(timeoutRef.current);

            isAnimating.current = false;
        }
    };

    React.useEffect(() => () => clearTimeout(timeoutRef.current), []);

    return (
        <Card className={styles['dock-card-container']}>
            <div
                className={styles['dock-card']}
                onClick={handleClick}
                style={{
                    width: size,
                    margin: 5
                }}
            >
                {children}
            </div>
        </Card>
    );
};
