/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import * as React from 'react';

import { useWindowResize } from '../hooks/useWindowResize';

import { DockContext } from './DockContext';

import styles from './style.module.scss';
import { Card } from '@mui/material';

interface DockProps {
    children: React.ReactNode;
}

export const DOCK_ZOOM_LIMIT = [-100, 50];

export const Dock = ({ children }: DockProps) => {
    const [hovered, setHovered] = React.useState(false);
    const [width, setWidth] = React.useState(0);
    const isZooming = React.useRef(false);
    const dockRef = React.useRef<HTMLDivElement>(null!);

    const setIsZooming = React.useCallback((value: boolean) => {
        isZooming.current = value;
        setHovered(!value);
    }, []);

    useWindowResize(() => {
        setWidth(dockRef.current.clientWidth);
    });

    return (
        <DockContext.Provider value={{ hovered, setIsZooming, width }}>
            <Card
                ref={dockRef}
                className={styles.dock}
                onMouseOver={() => {
                    if (!isZooming.current) {
                        setHovered(true);
                    }
                }}
                onMouseOut={() => {
                    setHovered(false);
                }}
            >
                {children}
            </Card>
        </DockContext.Provider>
    );
};
