import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    TextField,
    IconButton,
    Typography,
    Paper,
    List,
    ListItem,
    ListItemText,
    useMediaQuery,
    useTheme,
    Box,
    Card
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AppsIcon from '@mui/icons-material/Apps';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import MusicVideoIcon from '@mui/icons-material/MusicVideo';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';

const mockApps = [
    {
        id: 1,
        name: 'CRM',
        category: 'work',
        description: 'Description of CRM',
        icon: <AppsIcon sx={{ fontSize: 48, color: 'primary.main' }} />
    },
    {
        id: 2,
        name: 'Painter',
        category: 'creativity',
        description: 'Description of Painter',
        icon: <ColorLensIcon sx={{ fontSize: 48, color: 'primary.main' }} />
    },
    {
        id: 3,
        name: 'My Mp3',
        category: 'music',
        description: 'Description of My Mp3',
        icon: <MusicVideoIcon sx={{ fontSize: 48, color: 'primary.main' }} />
    },
    {
        id: 4,
        name: 'Toolkit',
        category: 'other',
        description: 'Description of Toolkit',
        icon: <AutoFixHighIcon sx={{ fontSize: 48, color: 'primary.main' }} />
    }
];

const categoryDatas = [
    { id: 0, category: 'all', label: 'Tất cả', icon: <AllInclusiveIcon sx={{ fontSize: 24 }} /> },
    { id: 1, category: 'work', label: 'Công việc', icon: <AppsIcon sx={{ fontSize: 24 }} /> },
    { id: 2, category: 'creativity', label: 'Sáng tạo', icon: <ColorLensIcon sx={{ fontSize: 24 }} /> },
    { id: 3, category: 'music', label: 'Âm nhạc', icon: <MusicVideoIcon sx={{ fontSize: 24 }} /> },
    { id: 4, category: 'other', label: 'Khác', icon: <AutoFixHighIcon sx={{ fontSize: 24 }} /> }
];

const ApplicationStore: React.FC<{}> = () => {
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedApp, setSelectedApp] = useState<any | null>(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const filteredApps = mockApps.filter(
        (app) => (selectedCategory ? app.category === selectedCategory : true) && app.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            <Grid container spacing={2} p={2}>
                <Grid item xs={12} md={3}>
                    <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold' }}>
                        Danh mục
                    </Typography>
                    <List style={{ overflowX: isMobile ? 'scroll' : 'visible', whiteSpace: isMobile ? 'nowrap' : 'normal' }}>
                        {categoryDatas.map((category) => (
                            <ListItem
                                button
                                key={category.id}
                                selected={
                                    selectedCategory === category.category || (category.category === 'all' && selectedCategory === null)
                                }
                                onClick={() => setSelectedCategory(category.category === 'all' ? null : category.category)}
                                sx={{
                                    display: 'inline-block',
                                    width: isMobile ? 'auto' : '100%',
                                    borderRadius: 4,
                                    transition: 'background-color 0.3s',
                                    '&:hover': {
                                        backgroundColor: '#e3f2fd' // Light blue background on hover
                                    },
                                    alignItems: 'center', // Center align items
                                    padding: 1 // Padding for better spacing
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {category.icon}
                                    <ListItemText primary={category.label} sx={{ ml: 1 }} /> {/* Add margin to the left of the label */}
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                <Grid item xs={12} md={9}>
                    <TextField
                        fullWidth
                        label="Tìm kiếm"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        sx={{
                            mb: 2,
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '4px'
                            },
                            '& .MuiInputLabel-root': {
                                color: '#1976d2' // Change label color
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                                color: '#1976d2'
                            }
                        }}
                    />
                    <Grid container spacing={2}>
                        {filteredApps.map((app) => (
                            <Grid item xs={12} sm={4} md={4} lg={4} key={app.id}>
                                <Paper
                                    elevation={3} // Slightly higher elevation for depth
                                    sx={{
                                        padding: 2,
                                        cursor: 'pointer',
                                        textAlign: 'center',
                                        borderRadius: 4, // More rounded corners
                                        transition: 'transform 0.3s, box-shadow 0.3s',
                                        '&:hover': {
                                            transform: 'scale(1.01)',
                                            boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)' // Increased shadow for a floating effect
                                        }
                                    }}
                                    onClick={() => setSelectedApp(app)}
                                >
                                    <Box
                                        sx={{
                                            width: 64, // Standardized icon size
                                            height: 64,
                                            mx: 'auto',
                                            mb: 2,
                                            borderRadius: 6,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' // Subtle shadow for the icon container
                                        }}
                                    >
                                        {app.icon}
                                    </Box>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1rem' }} noWrap>
                                        {app.name}
                                    </Typography>
                                    <Typography variant="caption">Miễn phí</Typography>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>

            {selectedApp && (
                <Dialog open={!!selectedApp} onClose={() => setSelectedApp(null)} sx={{ zIndex: 9999999 }}>
                    <DialogTitle>
                        {selectedApp.name}
                        <IconButton aria-label="close" onClick={() => setSelectedApp(null)} sx={{ position: 'absolute', right: 8, top: 8 }}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="body1">{selectedApp.description}</Typography>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

export default ApplicationStore;
