/* eslint-disable arrow-body-style */
import { createContext, useContext } from 'react';

type DockApi = {
    hovered: boolean;
    width: number;
    zoomLevel?: any;
    setIsZooming: (isZooming: boolean) => void;
};

export const DockContext = createContext<DockApi>({ width: 0, hovered: false, setIsZooming: () => {} });

export const useDock = () => {
    return useContext(DockContext);
};
