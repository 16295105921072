import { useState } from 'react';

export interface Window {
    id: string;
    title: string;
    content: React.ReactNode;
    defaultMaximize?: boolean;
    maxWidth?: string;
}

const useWindowManager = () => {
    const [windows, setWindows] = useState<Window[]>([]);
    const [activeWindow, setActiveWindow] = useState<string | null>(null);
    const [minimizedWindows, setMinimizedWindows] = useState<string[]>([]);
    const [clickedPrograms, setClickedPrograms] = useState<string[]>([]);

    const openWindow = (
        id: string,
        title: string,
        content: React.ReactNode,
        defaultMaximize: boolean = false,
        maxWidth: string = '100vw'
    ) => {
        if (!windows.find((win) => win.id === id)) {
            setWindows((prev) => [...prev, { id, title, content, defaultMaximize, maxWidth }]);
        }
        setActiveWindow(id);
        setMinimizedWindows((prev) => prev.filter((winId) => winId !== id)); // Restore if it was minimized
        setClickedPrograms((prev) => [...prev, id]); // Add to clicked programs
    };

    const handleFocus = (id: string) => {
        setActiveWindow(id);
        setMinimizedWindows((prev) => prev.filter((winId) => winId !== id)); // Restore if it was minimized
    };

    const closeWindow = (id: string) => {
        setWindows((prev) => prev.filter((win) => win.id !== id));
        if (activeWindow === id) {
            setActiveWindow(null);
        }
        setMinimizedWindows((prev) => prev.filter((winId) => winId !== id)); // Ensure minimized state is updated
        setClickedPrograms((prev) => prev.filter((programId) => programId !== id)); // Remove from clicked programs
    };

    const handleMinimize = (id: string) => {
        setMinimizedWindows((prev) => [...prev, id]);
    };

    const handleRestore = (id: string) => {
        setMinimizedWindows((prev) => prev.filter((winId) => winId !== id));
        handleFocus(id);
    };

    return {
        windows,
        activeWindow,
        minimizedWindows,
        clickedPrograms,
        openWindow,
        handleFocus,
        closeWindow,
        handleMinimize,
        handleRestore
    };
};

export default useWindowManager;
