import { useState } from 'react';
import { Dock } from './Dock';
import { DockCard } from './DockCard';
import { Card } from './Card';
import { DockDivider } from './DockDivider';
import AppStoreIcon from 'assets/images/dock/app-store.png';
import FinderIcon from 'assets/images/dock/chat.png';
import SettingIcon from 'assets/images/dock/setting.png';
import SettingDock from './Apps/Setting';
import AppStore from './Apps/AppStore';
import ChatMainPage from 'views/application/chat';
import { useWindowManagerContext } from './Apps/MacOSWindow/Provider/macwindow.provider';

const CustomizationIOS = () => {
    const { openWindow, clickedPrograms } = useWindowManagerContext();

    const GRADIENTS = [
        {
            id: 'AppStore',
            title: 'Kho ứng dụng',
            img: AppStoreIcon,
            event: () => {
                openWindow('AppStore', 'Kho ứng dụng', <AppStore />, true);
            }
        },
        {
            id: 'Finder',
            title: 'Liên hệ',
            img: FinderIcon,
            event: () => {
                openWindow('Finder', 'Liên hệ', <ChatMainPage />, true);
            }
        },
        {
            id: 'divider',
            title: '',
            img: null,
            event: null
        },
        {
            id: 'Setting',
            title: 'Cài đặt',
            img: SettingIcon,
            event: () => {
                openWindow('Setting', 'Cài đặt', <SettingDock />);
            }
        }
    ];

    return (
        <>
            <Dock>
                {GRADIENTS.map((item) =>
                    item.img ? (
                        <DockCard key={item.id}>
                            <div style={{ position: 'relative' }}>
                                {clickedPrograms.includes(item.id) && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: -5,
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                            width: 5,
                                            height: 5,
                                            borderRadius: '50%',
                                            backgroundColor: 'green'
                                        }}
                                    />
                                )}
                                <Card item={item} onClick={item.event} />
                            </div>
                        </DockCard>
                    ) : (
                        <DockDivider key={item.id} />
                    )
                )}
            </Dock>
        </>
    );
};

export default CustomizationIOS;
