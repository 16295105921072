/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/prefer-default-export */
import styles from './styles.module.scss';

export const Card = ({ item }: any) => (
    <span
        title={item?.title}
        className={styles.card}
        onClick={() => {
            if (item?.event) {
                item.event();
            }
        }}
    >
        <img className={styles.card__blur} src={item?.img} alt="" />
        <img className={styles.card__img} src={item?.img} alt="" />
    </span>
);
