/* eslint-disable arrow-body-style */
import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Drawer, Fab, Grid, IconButton, Tooltip } from '@mui/material';
import { IconSettings } from '@tabler/icons';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
// project imports
import Layout from 'layout/Customization/Layout';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';
import BorderRadius from 'layout/Customization/BorderRadius';
import FontFamily from 'layout/Customization/FontFamily';
import PresetColor from 'layout/Customization/PresetColor';

// ==============================|| LIVE CUSTOMIZATION ||============================== //

interface SettingDockProps {}

const SettingDock: React.FC<SettingDockProps> = () => {
    return (
        <>
            <PerfectScrollbar component="div">
                <Grid container spacing={gridSpacing} sx={{ p: 3 }}>
                    <Grid item xs={12}>
                        {/* layout type */}
                        <Layout />
                    </Grid>
                    <Grid item xs={12}>
                        {/* Theme Preset Color */}
                        <PresetColor />
                    </Grid>
                    <Grid item xs={12}>
                        {/* font family */}
                        <FontFamily />
                    </Grid>
                    <Grid item xs={12}>
                        {/* border radius */}
                        <BorderRadius />
                    </Grid>
                    {/* <Grid item xs={12}>
                                <InputFilled />
                            </Grid> */}
                    {/* <Grid item xs={12}>
                                <BoxContainer />
                            </Grid> */}
                </Grid>
            </PerfectScrollbar>
        </>
    );
};

export default SettingDock;
